import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="centered-label">Gaashaan.com</div>
    </div>
  );
}

export default App;
